.border-right {
  border-right: 1px solid $black1;
}

.border-top {
  border-top: 1px solid $black1;
}

.border-bottom {
  border-bottom: 1px solid $black1;
}

@media screen and (max-width: $screen-medium) {
  .border-right {
    border-right: none;
    border-bottom: 1px solid $black1;
  }
}
