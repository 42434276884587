.text-big_centered {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}

.text-color {
  &-green {
    color: #1a4730;
  }

  &-brown {
    color: #bb6a3e;
  }

  &-dark-brown {
    color: #78403e;
  }

  &-black {
    color: $black1;
  }
}

.block-heading {
  margin-bottom: 5rem;
  color: #78403e;

  & span {
    padding: 10px 0;
    border-bottom: 2px solid #78403e;
  }
}

.marquee {
  animation: marquee 30s linear 0s infinite normal;
  position: absolute;

  &:hover {
    animation-play-state: paused;
  }
}

@keyframes marquee {
  0% {
    left: 0;
  }

  100% {
    left: -100%;
  }
}
