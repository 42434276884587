.form {
  display: flex;
  width: 100%;

  &-center {
    justify-content: center;
  }
}

.input-container {
  position: relative;
  display: flex;
}

.input {
  font-size: 1.8rem;
  padding: 10px 0;
  display: block;
  width: 100%;
  border: none;
  border-radius: 0;
  border-bottom: 1px solid $black1;
  line-height: 1.15;
  background: transparent;

  @include prefix(transition, 0.2s ease all, webkit moz ms);

  &:focus,
  &:valid {
    box-shadow: 0 1px 0 #000;
  }

  &:focus ~ label,
  &:valid ~ label {
    top: -15px;
    font-size: 14px;
    color: #666;
  }
}

.label {
  color: $black1;
  font-size: 16px;
  font-weight: normal;
  position: absolute;
  pointer-events: none;
  left: 0;
  top: 17px;

  @include prefix(transition, 0.2s ease all, webkit moz ms);

  .footer-email &,
  .signup-white & {
    color: $white;
  }
}

.input-container_signup {
  position: relative;
  height: 50px;
  align-items: center;
  display: flex;
  width: 100%;
  max-width: 450px;
}

.input-signup {
  width: 100%;
  height: 100%;
  outline: none;
  background-color: transparent;
  border: none;
  border-bottom: 1px solid $black1;
  font-size: 16px;
  color: $black1;

  .footer-email &,
  .signup-white & {
    border-bottom: 1px solid $white;
    color: $white;
  }

  &:focus,
  &:valid {
    box-shadow: 0 1px 0 $black1;

    .footer-email &,
    .signup-white & {
      box-shadow: 0 1px 0 $white;
    }
  }

  &:focus ~ label,
  &:valid ~ label {
    top: -10px;
    font-size: 14px;
    color: $black2;

    .footer-email &,
    .signup-white & {
      color: $black4;
    }
  }
}
