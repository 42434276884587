footer {
  width: 100%;
  padding: 6vh 4vw 8vh;
  color: $white;
  background: $black1;
  font-size: 1.6rem;
}

.footer-logo {
  margin-bottom: 10vh;
}

.footer-body {
  @include prefix(display, flex, webkit ms);
  @include prefix(justify-content, space-between, webkit ms);
}

.footer-list {
  @include prefix(display, flex, webkit ms);
  @include prefix(justify-content, space-between, webkit ms);

  width: 33%;
}

.footer-links {
  width: 33%;
}

.footer-link {
  display: block;
  margin-bottom: 10px;
  color: $white;
}

.footer-email {
  width: 50%;

  form {
    @include prefix(justify-content, flex-end, webkit ms);
  }

  .alert-subscribe {
    @include prefix(display, flex, webkit ms);
    @include prefix(justify-content, flex-end, webkit ms);
  }
}

.footer-copyright {
  text-align: center;
  margin-top: 10vh;
  color: $grey;
}

@media screen and (max-width: $screen-medium) {
  .footer-list,
  .footer-body {
    flex-direction: column;
  }

  .footer-links {
    width: 100%;
  }

  .footer-link {
    padding-bottom: 10px;
  }

  .footer-email {
    width: 100%;
    margin-top: 20px;
  }
}
