.accordion {
  width: 100%;
  max-width: 800px;
  margin: 0 auto;
}

.accordion-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 4vh 0;
  cursor: pointer;
  margin-bottom: 0;
}

.accordion-icon {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 1.5rem;
  height: 1.5rem;
  transition: transform 0.3s;
}

.accordion-icon svg {
  transform-origin: center;
  transition: transform 0.3s ease-in-out;
}

.accordion-content {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.3s;
}

.open {
  max-height: 10rem;
}

.rotate {
  transform: rotate(45deg);
}
