.small-nav {
  display: none;
}

@media screen and (max-width: $screen-small) {
  .small-nav {
    position: relative;
    top: 0;
    left: 0;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-left: 4vw;
    padding-right: 4vw;
    height: 60px;
    background: rgba($white, 0.95);
    box-shadow: 0 1px $black1;

    &.active {
      background: $white;
    }
  }

  .small-nav-trigger {
    background: none;
    border: 0;
    display: block;
    padding: 10px 10px 10px 0;
    position: relative;
    height: 30px;
    width: 30px;
    cursor: pointer;

    &::before,
    &::after {
      content: '';
      height: 2px;
      top: 10px;
      position: absolute;
      background-color: $black1;
      transition: transform 200ms ease-in-out;
      width: 16px;
    }

    &::after {
      top: 18px;
    }

    &.active {
      &::before {
        transform: rotate(45deg);
        top: 13px;
      }

      &::after {
        transform: rotate(-45deg);
        top: 13px;
      }
    }
  }

  .small-nav-overlay {
    display: none;
    height: 100%;
    position: fixed;
    width: 100%;
    top: 60px;
    left: 0;
    background: $white;

    &.active {
      display: block;
    }
  }

  .small-nav-list {
    opacity: 0;
    padding: 4vw;

    &.active {
      opacity: 1;
    }
  }

  .small-nav-item {
    position: relative;
    font-size: 14vw;
    line-height: 1.5;
    font-weight: 300;
    font-family: $font-serif;

    &_link {
      position: relative;
      color: black;
    }

    .small-nav-list_bottom & {
      font-size: 5vw;
      font-family: inherit;
      line-height: 2;
    }
  }

  .small-nav-separator {
    margin: 20px 0;
    width: 50%;
    border-top: 1px solid black;
  }

  .small-nav-action-item {
    position: relative;
    font-size: 10vw;
    line-height: 1.5;
    font-weight: 300;
    font-family: $font-serif;

    &_link {
      position: relative;
      color: black;
    }
  }

  .small-nav-list_bottom {
    margin-top: 20px;
  }

  .small-nav-logo img {
    height: 18px;
  }
}
