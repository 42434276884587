.intro {
  height: 100%;
}

.intro-image {
  height: 100vh;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.intro-text {
  padding: 15vh 4vw;

  &_large {
    padding: 25vh 4vw;
  }
}

.intro-headline {
  margin-top: 30px;
  margin-bottom: 20px;
}

@media screen and (max-width: $screen-medium) {
  .intro-image {
    height: 75vh;
  }
}

@media screen and (max-width: $screen-small) {
  .intro-headline {
    font-size: 12vw;
    margin-top: 0;
  }
}
