.book-detail {
  height: 100%;

  &__wrap {
    padding: 10rem;
    margin: 0 auto;
    display: flex;
    flex-direction: row;
    height: 100%;
    max-width: 106rem;

    &__cover {
      padding-top: 2rem;
      margin-right: 10rem;
      margin-bottom: 2rem;

      &__title {
        text-align: center;
        text-transform: capitalize;
        display: none;
        margin-top: 2rem;

        @media screen and (max-width: $screen-medium) {
          display: block;
        }
      }

      &__authors {
        text-align: center;
        display: none;
        margin-bottom: 5rem;
        max-width: 100%;

        @media screen and (max-width: $screen-medium) {
          display: block;
        }
      }

      img {
        display: block;
        min-height: 300px;
        width: 20rem;
        margin: 0 auto;
        transition: all 0.18s ease-in-out;
        opacity: 1;
        visibility: visible;

        @media screen and (max-width: $screen-medium) {
          min-height: 140px;
          width: 10rem;
        }
      }

      &__controls {
        min-width: 24rem;
        margin-top: 4rem;

        &__add {
          width: 100%;
          margin-bottom: 1rem;
        }

        &__buy-wrap {
          width: 100%;
          margin-top: 2rem;

          &__buy {
            &.button {
              border-radius: 0.4rem 0.4rem 0 0;
              border: 1px solid $burnt-sienna;
              color: $burnt-sienna;
              background-color: transparent;
              z-index: 2;
              width: 100%;
            }

            i {
              display: inline-block;
              font-size: 1.8rem;
              line-height: 1rem;
              vertical-align: middle;
              transform: rotate(0deg);
              transition: all 0.15s ease-in-out;
            }

            &:hover {
              background-color: $seashell-light;
            }

            &.buy-options-open {
              border: 1px solid $burnt-sienna;
              background-color: $seashell;

              i {
                transform: rotate(180deg);
              }
            }

            &__options {
              display: none;
              width: 100%;
              overflow: hidden;
              height: auto;
              margin: 0;
              padding: 0;
              border: 1px solid $burnt-sienna;
              border-top: none;
              border-radius: 0 0 0.4rem 0.4rem;
              top: -2px;
              z-index: 1;

              // transition: max-height 0.5s ease-out;

              li {
                list-style-type: none;
                padding: 1.4rem 1.5rem;
                background-color: $seashell;
                border-bottom: 1px solid $burnt-sienna;
                color: $black1;
                font-size: 13px;
                font-size: 1.3rem;
                cursor: pointer;
                transition: all 0.2s ease-in-out;

                & > a {
                  text-decoration: none;
                  color: inherit;
                }

                .book-seller {
                  display: inline-block;
                  font-size: 13px;
                  font-size: 1.3rem;

                  .book-seller-message {
                    display: block;
                    margin-top: 0.5rem;
                    font-size: 11px;
                    font-size: 1.1rem;
                  }
                }

                .book-price {
                  display: block;
                  float: right;
                }

                &:last-child {
                  border-bottom: none;
                }

                &:hover {
                  background-color: $seashell-dark;
                  color: $black1;
                }
              }
            }
          }
        }
      }

      @media screen and (max-width: $screen-medium) {
        margin: 0 auto;
        margin-bottom: 4rem;
      }
    }

    &__book-info {
      &__title {
        text-transform: capitalize;

        @media screen and (max-width: $screen-medium) {
          display: none;
        }
      }

      p {
        margin-bottom: 1rem;
      }

      &__description {
        font-size: 16px;
        padding: 1rem 0;
        max-width: 100%;

        p {
          max-width: 100%;
          font-size: inherit;
        }
      }

      &__read-more-btn {
        text-align: center;
        margin: 0;
        opacity: 0.6;

        i {
          font-size: 20px;
          font-size: 2rem;
          line-height: 1rem;
          vertical-align: middle;
          transition: all 0.18s ease-in-out;
        }

        &.open {
          i {
            transform: rotate(180deg);
          }
        }
      }

      .have-you-read {
        max-width: 100%;
        margin-top: 2rem;

        span {
          cursor: pointer;
        }
      }

      &__authors {
        margin-bottom: 2rem;

        @media screen and (max-width: $screen-medium) {
          display: none;
        }
      }

      .book-meta-list {
        margin: 0;
        padding: 2rem 0;
        margin-top: 1rem;
        border-top: 1px solid #e4e1d7;
        border-bottom: 1px solid #e4e1d7;

        li {
          list-style-type: none;
          padding: 0.75rem 0;

          .meta-field {
            // font: $body2;
          }

          .meta-value {
            float: right;
          }
        }
      }
    }

    @media screen and (max-width: $screen-medium) {
      display: block;
      padding: 3rem;
      padding-top: 10rem;
    }
  }
}
