.big-text_link {
  font-family: 'lemur trial', sans-serif;
  font-weight: 500;
  -webkit-text-fill-color: transparent;
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: black;
  cursor: pointer;

  &:hover {
    color: black;
    -webkit-text-fill-color: black;
    -webkit-text-stroke-width: 0;
    -webkit-text-stroke-color: transparent;
  }

  &-white {
    font-family: 'lemur trial', sans-serif;
    font-weight: 500;
    -webkit-text-fill-color: transparent;
    -webkit-text-stroke-width: 1px;
    -webkit-text-stroke-color: $white;
    cursor: pointer;

    &:hover {
      color: $white;
      -webkit-text-fill-color: $white;
      -webkit-text-stroke-width: 0;
      -webkit-text-stroke-color: transparent;
    }
  }
}
