@font-face {
  font-family: Portrait;
  src: url('../fonts/Portrait-Light-Web.woff2') format('woff2'),
    url('../fonts/Portrait-Light-Web.woff') format('woff');
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: Lemur;
  src: url('../fonts/Lemur-Regular.woff2') format('woff2'),
    url('../fonts/Lemur-Regular.woff') format('woff');
  font-weight: 400;
  font-style: normal;
  font-stretch: normal;
}
