/* Colors */

$orange1: #f4efec;
$white: #fff;
$white-smoke: #efefef;
$green: #1d5330;
$burnt-sienna: #bb6a3e;
$seashell: #f7f5f3;
$seashell-dark: #ece8e4;
$seashell-light: #fdfcfb;
$grey: #a6a6a6;
$black1: #000;
$black2: #717170;
$black3: #aaaaa8;
$black4: #cfcfcc;
$black5: #e4e4e3;
$black6: #f2f2ef;

/* Typography */

$font-sans: 'Lemur', avenir, sans-serif;
$font-serif: 'Portrait', georgia, serif;

/* Layouts & Units */

$space-xsmall: 1vw;
$space-small: 2vw;
$space-medium: 4vw;
$space-large: 8vw;
$space-xlarge: 16vw;

/* Breakpoints */

$screen-medium: 1000px;
$screen-small: 700px;

/* Animations */

$fast: 200ms;
$slow: 400ms;
