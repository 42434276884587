.background-tan {
  background: #f4f3ee;
}

.background-pink {
  background: #f3efef;
}

.background-green {
  background: #f1f4f2;
}

.background-dark_green {
  background: #1a4730;
  color: $white;
}

.background-dark_orange {
  background: #b57449;
  color: $white;
}

.background-dark_red {
  background: #78403e;
  color: $white;
}
