.mark {
  position: relative;

  &::after {
    content: '';
    position: absolute;
    background-size: 100% 100%;
    pointer-events: none;
  }
}

.mark2 {
  position: absolute;
  top: 60%;
  height: 6vw;
}

.arrow-down {
  position: absolute;
  bottom: 4vw;
  height: 12vw;
  right: 4vw;
}

.mark-circle::after {
  background-image: url("../images/mark-circle.svg");
  top: -5%;
  left: -5%;
  width: 110%;
  height: 110%;
}

.mark-arrow::after {
  background-image: url("../images/mark-arrow.svg");
  top: -5%;
  left: -5%;
  width: 110%;
  height: 110%;
}

.mark-underline::after {
  background-image: url("../images/mark-underline.svg");
  top: 70%;
  left: -5%;
  width: 110%;
  height: 40%;
}

.highlight {
  position: relative;

  &::after {
    content: '';
    position: absolute;
    background-size: 100% 100%;
    pointer-events: none;
    z-index: -1;
  }
}

.highlight-pink::after {
  background-image: url("../images/highlight-pink.svg");
  top: -5%;
  left: -5%;
  width: 110%;
  height: 110%;
}

.highlight-peach::after {
  background-image: url("../images/highlight-peach.svg");
  top: -5%;
  left: -5%;
  width: 110%;
  height: 110%;
}

.highlight-green::after {
  background-image: url("../images/highlight-green.svg");
  top: -5%;
  left: -5%;
  width: 110%;
  height: 110%;
}

@media screen and (max-width: $screen-small) {
  .mark2 {
    display: none;
  }

  .arrow-down {
    height: 20vw;
  }
}
