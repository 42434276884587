.alert {
  padding: 10px 4vw;
  text-align: center;
  font-size: 14px;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 10;

  &-close {
    display: none;
  }

  &-info {
    color: #fff;
    background: $black1;
  }
}

.alert-subscribe {
  font-size: 1.4rem;
  line-height: 1.3;
  margin-top: 10px;
  max-width: 450px;
}

.alert-failure {
  color: #963d39;

  &.alert-text-reverse {
    color: color.adjust(#963d39, $lightness: 20%);
  }
}

.alert-success {
  color: #425238;

  &.alert-text-reverse {
    color: color.adjust(#425238, $lightness: 25%);
  }
}

.alert-center {
  margin: 10px auto;
}

.alert-right {
  float: right;
}
