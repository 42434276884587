.grid-wrap {
  width: 100%;
  position: relative;

  @include prefix(display, flex, webkit ms);
  @include prefix(justify-content, space-between, webkit ms);
  @include prefix(flex-flow, row wrap, webkit ms);
}

.grid-wrap_padding {
  padding: 20vh 4vw;

  &-small {
    padding: 8vh 4vw;
  }

  &-bottom {
    padding-bottom: 20vh;
    padding-left: 4vw;
    padding-right: 4vw;
  }

  &-top {
    padding-top: 20vh;
    padding-left: 4vw;
    padding-right: 4vw;
  }

  &-vertical {
    padding-top: 16vh;
    padding-bottom: 16vh;
  }

  &-vertical-top {
    padding-top: 16vh;
    padding-bottom: 8vh;
  }

  &-vertical-small {
    padding-top: 8vh;
    padding-bottom: 8vh;
  }
}

.grid-wrap_item {
  position: relative;
  width: 100%;
  display: block;

  &-25 {
    width: 25%;
  }

  &-50 {
    width: 50%;
  }

  &-75 {
    width: 75%;
  }
}

.grid-wrap_item-centered {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.grid-wrap_item-padding {
  padding: 4vw;

  &-large {
    padding: 10vw;
  }

  &-left {
    padding-left: 4vw;
  }

  &-right {
    padding-right: 4vw;
  }

  &-horizontal {
    padding-right: 4vw;
    padding-left: 4vw;
  }

  &-bottom {
    padding-bottom: 4vw;
  }

  &-top {
    padding-top: 4vw;
  }

  &-top-large {
    padding-top: 8vw;
  }

  &-vertical {
    padding-top: 4vw;
    padding-bottom: 4vw;
  }
}

.grid-wrap_item-order0 {
  order: 0;
}

.grid-wrap_item-order1 {
  order: 0;
}


@media screen and (max-width: $screen-medium) {
  .grid-wrap_item {
    &-25 {
      width: 50%;
    }

    &-50,
    &-75 {
      width: 100%;
    }
  }

  .grid-wrap_item-padding {
    padding: 10vw 4vw;

    &-left,
    &-right {
      padding: 4vw 0;
    }
  }

  .grid-wrap_item-order0 {
    order: 0;
  }

  .grid-wrap_item-order1 {
    order: 1;
  }
}

@media screen and (max-width: $screen-small) {
  .grid-wrap_item {
    &-25 {
      width: 100%;
    }

    &-mobile-50 {
      width: 50% !important;
    }
  }
}
