.book-list-head {
  padding: 3rem 6rem 0;
}

.book-carousel {
  position: relative;
  display: flex;
  overflow-x: scroll;
  overflow-y: hidden;
  padding: 3rem 8rem 6rem 6rem;

  &::after {
    content: '';
    flex: 0 0 1rem;
  }
}

.book-card {
  position: relative;
  text-align: left;
  flex-wrap: nowrap;
  width: 18rem;
  margin-right: 7rem;

  &__meta {
    position: relative;
    margin-top: 1.7rem;

    p {
      margin-bottom: 0.5rem;
    }

    p:last-child {
      margin-bottom: 1rem;
    }

    &__title {
      position: relative;
      width: 80%;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      font-size: 16px;
      line-height: 1.25;
    }

    &__author {
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      font-size: 14px;
      line-height: 1.5;
    }

    &__curation-details {
      font-size: 14px;
      line-height: 1.75;
    }
  }

  &__cover-link {
    position: relative;
    width: 18rem;
    height: 26rem;
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    border: 8px solid white;
    box-shadow: 0 3px 9px 1px rgb(43 36 13 / 8%);

    @media screen and (max-width: $screen-small) {
      width: 15rem;
      height: 22rem;
      border: 6px solid white;
    }
  }

  @media screen and (max-width: $screen-small) {
    width: 15rem;
    margin-right: 2rem;
  }
}
