.button {
  font-family: $font-sans;
  line-height: 1;
  margin: 0;
  cursor: pointer;
  font-weight: 500;
  text-align: center;
  border-style: none;
  font-size: 1.6rem;

  @include prefix(transition, 0.2s ease all, webkit moz ms);

  padding: 1.5rem 2.5rem;
  display: inline-block;
  border-radius: 1px;

  &-small {
    padding: 8px 15px;
    border-radius: 4px;
  }
}

.button-primary {
  background: #bb6a3e;
  color: $white;

  &:hover {
    opacity: 0.8;
  }
}

.button-secondary {
  background: #1a4730;
  color: $white;

  &:hover {
    opacity: 0.8;
  }
}

.button-tertiary {
  background: #78403e;
  color: $white;

  &:hover {
    opacity: 0.8;
  }
}

.button-signup {
  border: none;
  cursor: pointer;
  background-color: transparent;
  top: 50%;

  @include prefix(transform, translateY(-50%), webkit ms);
  @include prefix(transition, opacity 0.2s, webkit ms);

  position: absolute;
  right: 0;
  padding: 15px 0 15px 15px;

  &:hover {
    opacity: 0.5;
  }

  .footer-email &,
  .signup-white & {
    color: $white;
  }
}
