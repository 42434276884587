.illustration {
  display: block;
  height: auto;
  width: 100%;
  max-height: 12rem;
  margin: 20px auto 40px;
}

.image {
  width: 100%;
  height: 100%;

  &-60 {
    width: 60%;
    height: auto;
  }
}

.image-responsive.mobile {
  display: none;
}

@media screen and (max-width: $screen-small) {
  .image-responsive {
    display: none;
  }

  .image-responsive.mobile {
    display: block;
  }
}

.homepage-image {
  background-image: url("../images/woman-reading@2x.jpg");
}

.about-image {
  background-image: url("../images/holding-books@2x.jpg");
}

.app-description-image {
  background-image: url("../images/product-with-book@2x.jpg");
}

.image-icon {
  width: 60px;
  height: 20px;
}

.image-error {
  max-height: 16rem;
  margin-bottom: 20px;
}

.image-float {
  &-right {
    float: right;
  }

  &-left {
    float: left;
  }
}
