h1,
.h1,
h2,
.h2,
h3,
.h3 {
  font-family: $font-serif;
  font-weight: 300;
  font-style: normal;
  /* stylelint-disable-next-line value-keyword-case */
  text-rendering: optimizeLegibility;
}

h1,
.h1 {
  font-size: 6vw;
  line-height: 1.2;
}

h2,
.h2 {
  font-size: 5vw;
  line-height: 1.3;
}

h3,
.h3 {
  font-size: 4vw;
  line-height: 1.2;
}

h4,
.h4 {
  font-size: 1.8rem;
  font-weight: 500;
  line-height: 1.3;
  margin-bottom: 20px;
}

a {
  text-decoration: none;
}

p,
.p {
  margin-bottom: 20px;
  font-family: inherit;
  font-weight: normal;
  font-size: 18px;
  line-height: 1.75;
  max-width: 400px;
  opacity: 0.7;

  &_wide {
    max-width: 650px;
  }

  &_body {
    max-width: 800px;
  }

  a {
    text-decoration: underline;
    color: inherit;
    cursor: pointer;

    &:hover {
      opacity: 0.7;
    }
  }
}

.text-small {
  font-size: 1.6rem;
  margin-bottom: 20px;
}

.bold {
  font-weight: bold;
}

em,
i {
  font-style: italic;
}

.outline-link {
  font-size: 6vw;
  line-height: 1.1;
  -webkit-text-fill-color: transparent;
  -webkit-text-stroke-width: 0.8px;
  -webkit-text-stroke-color: black;
}

.text-center {
  margin-left: auto;
  margin-right: auto;
  text-align: center;
}

.text-left {
  text-align: left;
}

::selection {
  color: $white;
  background: $black1;
}

// Margin utility classes
.mb-0 {
  margin-bottom: 0;
}

.mt-small {
  margin-top: 4vh;
}

.mb-small {
  margin-bottom: 4vh;
}

blockquote {
  position: relative;
  margin: 0;
}

blockquote p::before {
  content: "\201C";
  color: $black1;
}

blockquote p::after {
  content: "\201D";
  color: $black1;
}

blockquote cite {
  display: block;
  text-align: right;
  font-size: 1.8rem;
  font-weight: normal;
  font-style: italic;
  margin-top: -15px;
}

@media screen and (max-width: 1000px) {
  h1,
  .h1 {
    font-size: 8vw;
  }

  h2,
  .h2 {
    font-size: 6vw;
  }

  h3,
  .h3 {
    font-size: 6vw;
  }

  h4,
  .h4 {
    font-size: 3vw;
  }
}

@media screen and (max-width: 700px) {
  h1,
  .h1 {
    font-size: 12vw;
  }

  h2,
  .h2 {
    font-size: 10vw;
  }

  h3,
  .h3 {
    font-size: 10vw;
  }

  h4,
  .h4 {
    font-size: 6vw;
  }

  .p_wide {
    max-width: 400px;
  }

  .p_body {
    max-width: 400px;
  }
}
