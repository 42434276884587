/* Navigation */

header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 5;
}

.nav {
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  display: flex;
  align-items: center;
  padding: 6vh 4vw;
  transition: $fast;

  &.scroll {
    background: rgba($white, 0.95);
    padding: 3vh 4vw;
    border-bottom: 1px solid $black1;
  }
}

.nav-list {
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  padding: 0;
  margin: 0;
  display: flex;
  justify-content: left;
  list-style: none;
}

.nav-item {
  position: relative;
  font-size: 14px;
  line-height: 1.4;
  font-weight: 300;
  margin-right: 30px;
  margin-bottom: 5px;

  &_link {
    position: relative;
    color: black;

    &:hover {
      opacity: 0.5;
    }
  }

  &.active {
    border-bottom: 1px solid $black1;
  }
}

.nav-actions-list {
  position: relative;
  padding: 0;
  margin: 0;
  display: flex;
  justify-content: right;
  list-style: none;

  div:first-of-type {
    padding: 0 15px 0 0;
  }

  div:last-child {
    padding: 0 0 0 15px;
  }
}

.nav-action-item {
  position: relative;
  font-size: 14px;
  line-height: 1.4;
  font-weight: 300;
  margin-bottom: 5px;

  &_link {
    position: relative;
    color: black;

    &--primary {
      color: #bb6a3e;
    }

    &:hover {
      opacity: 0.5;
    }
  }
}

.nav-action-divider {
  position: relative;
  font-size: 14px;
  line-height: 1.4;
  font-weight: 300;
  margin-bottom: 5px;
}

.nav-logo {
  margin-right: 30px;
}

.nav-logo img {
  height: 20px;
}

@media screen and (max-width: $screen-small) {
  .nav {
    display: none;
  }
}
