* {
  box-sizing: border-box;
}

html {
  font-family: sans-serif;
  text-size-adjust: 100%;
  font-size: 10px;
}

body {
  position: relative;
  background-color: $white;
  font-family: $font-sans;
  color: #000;
  width: 100vw;
  height: 100vh;
  font-size: 1.6rem;
  overflow-x: hidden;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
}

.main-content {
  opacity: 0;
  transition: opacity 0.4s cubic-bezier(0.25, 0.46, 0.45, 0.94);

  &.animate {
    opacity: 1;
  }
}
