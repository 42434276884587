.terms {
  margin: 2rem;
  max-width: 400px;
  line-height: 1.75;
  font-size: 1.6rem;
}

.alphanumeric-list-style {
  list-style-type: lower-alpha;
}

.roman-list-style {
  list-style-type: lower-roman;
}

.inside-list-style {
  list-style: inside;
}
